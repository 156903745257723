import Forms from './components/forms';
import axios from "axios";
import 'selectize';

class ExtranetForms {
    init() {
        const userForm = document.getElementById('adduser');
        const uploadButtons = document.querySelectorAll('.upload-button');
        const toggleBtns = document.querySelectorAll('.btn-toggle');

        const url = new URL(window.location.href);
        const defaultTab = url.searchParams.get("tab");

        if (defaultTab) {
            $('#nav-tab a[href="#' +defaultTab+ '"]').tab('show');
        }

        if (uploadButtons) {
            Array.prototype.filter.call(uploadButtons, (btn) => {
                const parent = btn.closest('.picture-wrapper');
                const fileInput = parent.querySelector('.file-upload');
                const img = parent.querySelector('.profile-pic');

                btn.addEventListener('click', () => {
                    Forms.simulateClick(fileInput);
                });

                fileInput.addEventListener('change', (event) => {
                    Forms.readURL(event.target, img);
                });
            });
        }

        if (userForm) {
            Forms.passwordInput(document.getElementById('password1'));
            Forms.passwordInput(document.getElementById('password2'));
        }

        if (toggleBtns) {
            const wpeditors = document.querySelectorAll('.wp-editor-wrap');
            Array.prototype.filter.call(wpeditors, (wpeditor) => {
                wpeditor.classList.add('d-none');
            });

            Array.prototype.filter.call(toggleBtns, (btn) => {
                btn.addEventListener('click', (event) => {
                    Forms.toggleCardForm(event.target.closest('.card'), btn);
                });
            });
        }

        const individualInput = document.querySelector('#individual');
        const publicInput = document.querySelector('#public');
        const privateInput = document.querySelector('#private');

        if (individualInput && publicInput && privateInput && total) {
            this.cA(individualInput, publicInput, privateInput, total);
        }

        $('.cap_lvl_1').on('change', function() {
            let $value = $(this).val();
            const $row = $(this).closest('.row');
            const $selectorLvl2 = $row.find('.cap_lvl_2');

            axios
                .get('/wp-json/wp/v2/company_skills?parent=' + $value)
                .then((response) => {
                    $selectorLvl2[0].selectize.clear(true);
                    $selectorLvl2[0].selectize.clearOptions();

                    for (let i = 0; i < response.data.length; i++) {
                        let name = $('<textarea />').html(response.data[i].name).text();

                        $selectorLvl2[0].selectize.addOption({
                            value: response.data[i].id,
                            text: name.charAt(0).toUpperCase() + name.slice(1)
                        });
                    }
                });
        });

        $('.remove-skill').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.row').remove();
        });

        $('.remove-qualipaysage').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.container-forms-qualipaysage').remove();
        });

        $('.remove-sap_coop').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.row').remove();
        });

        const addSkill = document.querySelector('#add_skill');

        if (addSkill) {
            addSkill.addEventListener('click', (e) => {
                const skills = JSON.parse(e.target.dataset.skills);
                this.createSkillRow(skills);
            });
        }

        const addSapCoop = document.querySelector('#add_sap_coop');

        if (addSapCoop) {
            addSapCoop.addEventListener('click', (e) => {
                const sapCoops = JSON.parse(e.target.dataset.sapcoops);
                this.createCoopSAPRow(sapCoops);
            });
        }

        jQuery('#update_access').on('change', () => {
            this.handleAccessibleContent();
        });
        jQuery('#invite_access').on('change', () => {
            this.handleAccessibleContent();
        });

        jQuery('.edit-user').on('click', (event) => {
            let userName = event.target.getAttribute('data-username');
            if (userName == null) {
                userName = event.target.parentElement.getAttribute('data-username');
            }
            this.getUserData(userName);
        });

        jQuery('.delete-user').on('click', (event) => {
            let userId = event.target.getAttribute('data-userid');
            if (userId == null) {
                userId = event.target.parentElement.getAttribute('data-userid');
            }
            let userName = event.target.getAttribute('data-username');
            if (userName == null) {
                userName = event.target.parentElement.getAttribute('data-username');
            }
            this.deleteUser(userId, userName);
        });

        this.customFileInput();
    }

    customFileInput() {
        const fileInput = document.querySelector( ".input-file" );
        const button = document.querySelector( ".input-file-trigger" );
        const the_return = document.querySelector(".file-return");

        if (button && fileInput && the_return) {
            button.addEventListener( "keydown", function( event ) {
                if (event.keyCode == 13 || event.keyCode == 32) {
                    fileInput.focus();
                }
            });

            button.addEventListener( "click", function( event ) {
                fileInput.focus();
                return false;
            });

            fileInput.addEventListener( "change", function( event ) {
                the_return.innerHTML = this.value;
            });
        }
    }

    cA(individualInput, publicInput, privateInput) {
        const inputs = [
            individualInput,
            publicInput,
            privateInput
        ];

        const total = document.querySelector('#total');
        const totalResult = document.querySelector('#total-result');

        Array.prototype.filter.call(inputs, (input) => {
            total.innerText = this.calculateNbPercent(individualInput, publicInput, privateInput) + '%';
            this.calculateResult(input, parseFloat(totalResult.innerText ? totalResult.innerText.replace(/\s/g, '') : 0));

            input.addEventListener('change', (event) => {
                total.innerText = this.calculateNbPercent(individualInput, publicInput, privateInput) + '%';
                this.calculateResult(event.target, parseFloat(totalResult.innerText ? totalResult.innerText.replace(/\s/g, '') : 0));
            });
        });
    }

    calculateNbPercent(individualInput, publicInput, privateInput) {
        return (parseFloat(individualInput.value ? individualInput.value : 0)+parseFloat(publicInput.value ? publicInput.value : 0)+parseFloat(privateInput.value ? privateInput.value : 0))
    }

    calculateResult(input, total) {
        const result = document.querySelector('#' + input.id + '-result');
        let percentFormPercent = this.percentCalculation(total, input.value ? input.value : 0);

        result.innerText = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(percentFormPercent);
    }

    percentCalculation(a, b) {
        let calculated = (parseFloat(a)*parseFloat(b))/100;
        return parseFloat(calculated);
    }

    handleAccessibleContent() {
        let formSelect = document.getElementById('invite_access');
        let formSelectUpdate = document.getElementById('update_access');
        let accessibleContent = document.getElementById('accessible_content');
        let accessibleContentUpdate = document.getElementById('accessible_content_update');

        if (formSelect.value == 'member_intermediate') {
            accessibleContent.classList.remove('hidden');
        } else {
            accessibleContent.classList.add('hidden');
        }

        if (formSelectUpdate.value == 'member_intermediate') {
            accessibleContentUpdate.classList.remove('hidden');
        } else {
            accessibleContentUpdate.classList.add('hidden');
        }
    }

    getUserData(userName) {
        let formData = new FormData();
        formData.set('username', userName);

        axios
            .post('/espace-adherent/ma-structure/fetch-userdata', formData)
            .then((response) => {
                this.fillEditUser(response.data.data);
            })
            .catch((error) => {

            })
        ;
    }

    fillEditUser(data) {
        let email = document.getElementById("update_email");
        let firstName = document.getElementById("update_firstname");
        let lastName = document.getElementById("update_lastname");
        let userFunction = document.getElementById("update_function");
        let documentAccess = document.getElementById("documents_access_update");
        let adminAccess = document.getElementById("admin_access_update");
        let eudoId = document.getElementById("eudoId");
        let wpId = document.getElementById("wpId");
        let isContactSubscription = document.getElementById("update_contact_subscription");

        email.value = data.email;
        firstName.value = data.first_name;
        lastName.value = data.last_name;
        userFunction.value = data.main_function;
        isContactSubscription.checked = data.isContactSubscription;

        var $select = $("#update_access").selectize();
        var selectize = $select[0].selectize;
        selectize.setValue(data.role);

        if (data.capacities.unep_read_social_document) {
            documentAccess.checked = true;
        } else {
            documentAccess.checked = false;
        }
        if (data.capacities.unep_edit_company_page) {
            adminAccess.checked = true;
        } else {
            adminAccess.checked = false;
        }

        eudoId.value = data.eudoID;
        wpId.value = data.wpID;
    }

    deleteUser(userId, userName) {
        $("#deleteUser").val(userId);
        $("#textDelUser").text('Voulez-vous vraiment supprimer l\'utilisateur ' + userName + ' ?');
    }

    createSkillRow(skills) {
        const skillRows = document.querySelector('#skills-rows');
        const rows = skillRows.childElementCount;

        const mainrow = document.createElement('div');
        mainrow.classList.add('row');
        mainrow.classList.add('skill-' + (rows+1));

        const formgroup1 = document.createElement('div');
        formgroup1.classList.add('form-group');
        formgroup1.classList.add('col-md-6');

        const select1 = document.createElement('select');
        select1.classList.add('cap_lvl_1');
        select1.classList.add('select');
        select1.setAttribute('name', 'skills[' + (rows+1) +'][cap_lvl_1]');

        const option = document.createElement('option');
        option.setAttribute('value', '');
        option.innerText = 'Sélectionner une activité';

        const option2 = document.createElement('option');
        option2.setAttribute('value', '');
        option2.innerText = 'Sélectionner une activité';

        const formgroup2 = document.createElement('div');
        formgroup2.classList.add('form-group');
        formgroup2.classList.add('col-md-5');

        const select2 = document.createElement('select');
        select2.classList.add('cap_lvl_2');
        select2.classList.add('select');
        select2.setAttribute('name', 'skills[' + (rows+1) +'][cap_lvl_2]');

        const formgroup3 = document.createElement('div');
        formgroup3.classList.add('form-group');
        formgroup3.classList.add('col-md-1');

        const button = document.createElement('button');
        button.classList.add('remove-skill');
        button.setAttribute('type', 'button');
        button.innerHTML = '<i class="icon-close"></i>';

        select1.append(option);
        select2.append(option2);

        formgroup1.append(select1);
        formgroup2.append(select2);
        formgroup3.append(button);

        mainrow.append(formgroup1);
        mainrow.append(formgroup2);
        mainrow.append(formgroup3);

        skillRows.append(mainrow);

        $('.skill-' + (rows+1) + ' .cap_lvl_1').selectize();
        $('.skill-' + (rows+1) + ' .cap_lvl_2').selectize();

        jQuery.each(skills, function(i, val) {
            $('.skill-' + (rows+1) + ' .cap_lvl_1')[0].selectize.addOption({
                value: val.id,
                text: val.name
            });
        });

        $('.skill-' + (rows+1) + ' .cap_lvl_1').on('change', function() {
            let $value = $(this).val();
            const $row = $(this).closest('.row');
            const $selectorLvl2 = $row.find('.cap_lvl_2');

            axios
                .get('/wp-json/wp/v2/company_skills?parent=' + $value)
                .then((response) => {
                    $selectorLvl2[0].selectize.clear(true);
                    $selectorLvl2[0].selectize.clearOptions();

                    for (let i = 0; i < response.data.length; i++) {
                        let name = htmlentities.decode(response.data[i].name);

                        $selectorLvl2[0].selectize.addOption({
                            value: response.data[i].id,
                            text: name.charAt(0).toUpperCase() + name.slice(1)
                        });
                    }
                });
        });

        $('.skill-' + (rows+1) + ' .remove-skill').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.row').remove();
        });
    }

    createCoopSAPRow(sap_coops) {
        const sapCoopsRows = document.querySelector('#sap_coop-rows');
        const rows = sapCoopsRows.childElementCount;

        const mainrow = document.createElement('div');
        mainrow.classList.add('row');
        mainrow.classList.add('sap_coop-' + (rows+1));

        const formgroup1 = document.createElement('div');
        formgroup1.classList.add('form-group');
        formgroup1.classList.add('col-md-11');

        const select1 = document.createElement('select');
        select1.classList.add('sap_coop_lvl_1');
        select1.classList.add('select');
        select1.setAttribute('name', 'sap_coop[' + (rows+1) +']');

        const option = document.createElement('option');
        option.setAttribute('value', '');
        option.innerText = 'Sélectionner une coopérative SAP';

        const formgroup3 = document.createElement('div');
        formgroup3.classList.add('form-group');
        formgroup3.classList.add('col-md-1');

        const button = document.createElement('button');
        button.classList.add('remove-sap_coop');
        button.setAttribute('type', 'button');
        button.innerHTML = '<i class="icon-close"></i>';

        select1.append(option);

        formgroup1.append(select1);
        formgroup3.append(button);

        mainrow.append(formgroup1);
        mainrow.append(formgroup3);

        sapCoopsRows.append(mainrow);

        $('.sap_coop-' + (rows+1) + ' .sap_coop_lvl_1').selectize();

        jQuery.each(sap_coops, function(i, val) {
            $('.sap_coop-' + (rows+1) + ' .sap_coop_lvl_1')[0].selectize.addOption({
                value: val.id,
                text: val.name
            });
        });

        $('.sap_coop-' + (rows+1) + ' .remove-sap_coop').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.row').remove();
        });
    }
}

export default new ExtranetForms();
